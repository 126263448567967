<script setup lang="ts">
import { resolveComponent } from 'vue';
import { pascalCase } from 'scule';
import {
  resolveCmsPath,
  PREVIEW_PAGE_COMPONENT,
  NOT_FOUND_COMPONENT_PLAIN,
} from '~/composables/usePublisher';
import { CmsPage } from '@shopware-pwa/types';
const config = useRuntimeConfig();

// Get 404 content from BE
const { data: errorPageResult } = await useAsyncData(
  'cmsResponse' + config.ERROR_PAGE_CMS_ID + 'buffer404cms',
  async () => await resolveCmsPath(config.ERROR_PAGE_CMS_ID)
);

// Render dynamic components from 404 cms or trigger plain 404
function render() {
  if (config.ERROR_PAGE_CMS_ID && errorPageResult?.value?.sections) {
    const cmsPage = errorPageResult.value as CmsPage;
    // return 404 cms
    return h('div', h(resolveComponent(PREVIEW_PAGE_COMPONENT), { cmsPage }));
  }

  // return plain 404
  return h('div', h(resolveComponent(pascalCase(NOT_FOUND_COMPONENT_PLAIN))));
}
</script>

<template>
  <render />
</template>
